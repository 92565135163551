import React, { useState } from 'react';

import clsx from 'clsx';
import ReactionSelect from '../../ReactionSelect';
import ActionsMenu from '../../ActionsMenu';
import { AddReactionPayload, Message } from '../../../../../hooks/api/chats';
import { User } from '../../../../../store/auth';
import socketProvider from '../../../../../socket';
import { useChatsProvider } from '../../../../../context/chats';
import CollapsePanel from '../../../CollapsePanel';

import styles from './index.module.scss';

const getMenuHeight = (align: 'left' | 'right', withoutHeading?: boolean): number => {
  const itemsHeight = (align === 'left' ? 3 : 4) * 31; // 31 - height of menu item with 1px border;

  return (
    withoutHeading ? itemsHeight : 42 + 8 + itemsHeight // 42 - height of main reactions row. 8 - gap
  );
};

interface ContextMenuProps {
  messageItem: Message;
  user: User;
  align?: 'left' | 'right';
}

function ContextMenu({
  messageItem, user, align = 'left',
}: ContextMenuProps) {
  const { selectedMessageId } = useChatsProvider();
  const {
    id, user: messageUser, chatId, reactions,
  } = messageItem;

  const handleReactionAdd = ({ messageId, reaction }: AddReactionPayload) => {
    if (!socketProvider.socket) return;

    socketProvider.socket.emit('reaction', { reaction, messageId, chat: chatId || '' });
  };

  // Reaction Select props:
  const [isReactionsExpanded, setIsReactionsExpanded] = useState(false);

  return (
    <div
      className={clsx(styles.contextMenu, {
        [styles.right]: align === 'right',
      })}
      style={{ minHeight: getMenuHeight(align) }} // 204px
      // Not gonna work well if length of actions in menu will change (204 = 5 items)
    >
      <ReactionSelect
        visible={selectedMessageId === id}
        className={clsx({ [styles.right]: messageUser?.id === user?.id })}
        align={messageUser?.id === user?.id ? 'right' : 'left'}
        onReaction={(reaction) => handleReactionAdd({ reaction, messageId: id })}
        disabledReactions={reactions?.map((item) => item.reaction) || []}
        expandProps={{
          defaultExpanded: isReactionsExpanded,
          onExpandedChange: (expanded) => setIsReactionsExpanded(expanded),
          maxHeight: getMenuHeight(align, true),
        }}
      />
      <CollapsePanel
        open={!isReactionsExpanded}
      >
        <ActionsMenu
          visible={selectedMessageId === id} //  && !isReactionsExpanded
          align={messageUser?.id === user?.id ? 'right' : 'left'}
          messageItem={messageItem}
        />
      </CollapsePanel>
    </div>
  );
}

export default ContextMenu;
