import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { Action, AnyObject } from '@triare/auth-redux';
import {
  signInByService as signInByServiceAction,
  // signInByServiceError as signInByServiceErrorAction,
  SignInByServiceAction,
} from '@triare/auth-redux/dist/saga/auth/signIn';
import { useTranslation } from 'react-i18next';
import { IResolveParams, LoginSocialApple } from 'reactjs-social-login';
import { Error } from '@triare/auth-redux/dist/saga/common';
import { RootState } from '../../../../../../store';
import { moduleName } from '../../../../../../store/auth';
import { ContentProps } from '../../index';

interface ButtonGoogleProps extends ContentProps {
  response: AnyObject | null;
  error: Error | null;
  signInByService: (payload: SignInByServiceAction) => Action;
  // signInByServiceError: () => Action;
}

function ButtonApple({
  response, error,
  onSubmit,
  signInByService,
  // signInByServiceError,
}: ButtonGoogleProps): React.ReactNode {
  const [signInData, setSignInData] = useState<SignInByServiceAction | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!error && response && onSubmit && signInData) {
      onSubmit(signInData);
    }
  }, [response, error, onSubmit, signInData]);

  // const [providerName, setProviderName] = useState('');

  const onLoginStart = useCallback(() => {
    // alert('login start')
  }, []);

  return (
    <LoginSocialApple
      client_id={process.env.REACT_APP_APPLE_ID || ''}
      scope="name email"
      onLoginStart={onLoginStart}
      redirect_uri={`${window.location.origin}/auth/callback/apple`}
      onResolve={({ provider, data }: IResolveParams) => {
        // setProviderName(provider);

        if (data && data.authorization && data.authorization.id_token) {
          const payload: SignInByServiceAction = {
            // _query: {
            //   lang: 'en',
            // },
            method: 'apple',
            accessToken: data.authorization.id_token,
          };

          setSignInData(payload);
          signInByService({
            method: 'apple',
            accessToken: data.authorization.id_token,
          });
        }
      }}
      onReject={(err) => {
        console.log(err);
      }}
    >
      <Button type="primary" style={{ width: '100%' }}>
        {t('signIn.apple')}
      </Button>
    </LoginSocialApple>
  );
}

export default connect((state: RootState) => ({
  response: state[moduleName].signIn.response,
  error: state[moduleName].signIn.error,
}), {
  signInByService: signInByServiceAction,
  // signInByServiceError: signInByServiceErrorAction,
})(ButtonApple);
