/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';
import { EnhancedStore } from '@reduxjs/toolkit/src/configureStore';
import { useSelector } from 'react-redux';
import { To } from '@remix-run/router';
import { NavigateOptions } from 'react-router-dom';
import { RootState } from '../index';

export const moduleName = 'common';

export interface State {
  redirect: { to: To, options?: NavigateOptions } | null
}

const initialState: State = {
  redirect: null,
};

export const common = createSlice({
  name: moduleName,
  initialState,
  reducers: {
    triggerRedirect: (state, { payload }) => {
      state.redirect = payload;
    },
  },
});

export const { actions } = common;

// action creator
export function triggerRedirect(to: To, options?: NavigateOptions) {
  return {
    type: actions.triggerRedirect.toString(),
    payload: {
      to,
      options,
    },
  };
}

export function useRedirectSelector(): State {
  return useSelector((state: RootState) => state[moduleName]);
}

export function* saga(store: EnhancedStore) {
  // do something
}
