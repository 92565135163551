import React from 'react';
import clsx from 'clsx';
import { CloseOutlined } from '@ant-design/icons';
import { useChatsProvider } from '../../../../context/chats';
import { getUserDisplayName } from '../utils';
import { IconPencilEdit, IconReply } from '../../Icon';

import styles from './index.module.scss';

function ActionPreview() {
  const { messageActionState, handleMessageActionState } = useChatsProvider();

  if (!messageActionState.message) return null;

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleMessageActionState(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    if (messageActionState.message) {
      const messageElement = document.getElementById(messageActionState.message?.id);

      if (messageElement) {
        messageElement.scrollIntoView({ behavior: 'auto', block: 'center' });

        // Highlight the message
        messageElement.classList.add(styles.highlight);
        setTimeout(() => {
          messageElement.classList.remove(styles.highlight);
        }, 1000);
      }
    }
  };

  return (
    <div
      className={clsx(styles.actionPreviewContainer, 'disable-user-select')}
      role="button"
      tabIndex={-1}
      aria-label="message-action-container"
      onClick={handleClick}
    >
      <div className={styles.actionIcon} title={messageActionState.mode === 'reply' ? 'Replying to' : 'Editing'}>
        {messageActionState.mode === 'reply' ? <IconReply /> : <IconPencilEdit />}
      </div>

      <div className={styles.mainContainer}>
        <span className={styles.replyUser}>
          {getUserDisplayName(messageActionState.message.user)}
        </span>

        <div className={clsx(styles.messageContent, 'truncate')}>
          {messageActionState.message.message}
        </div>
      </div>

      <button type="button" onClick={handleCancel} className={styles.cancelButton} aria-label="Cancel">
        <CloseOutlined />
      </button>
    </div>
  );
}

export default ActionPreview;
