import React, { useMemo } from 'react';
import clsx from 'clsx';
import { message } from 'antd';
import {
  IconContentCopy, IconDelete, IconForward, IconPencilEdit, IconReply,
} from '../../Icon';
import { useChatsProvider } from '../../../../context/chats';
import { Message } from '../../../../hooks/api/chats';
import socketProvider from '../../../../socket';

import styles from './index.module.scss';

const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
    message.success('Text copied to clipboard');
  }).catch((error) => {
    message.error('Failed to copy text:', error);
  });
};

interface OptionsList {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}

interface ActionsMenuProps {
  align?: 'left' | 'right';
  className?: string;
  visible: boolean;
  messageItem: Message;
}

const handleMessageDelete = ({ messageId }: { messageId: string; }) => {
  if (!socketProvider.socket) return;

  socketProvider.socket.emit('delete-message', { id: messageId });
};

function ActionsMenu({
  className, visible, align = 'left', messageItem,
}: ActionsMenuProps) {
  const {
    selectedMessageId, handleSelectMessage, handleConfirmPopup,
    messageActionState, handleMessageActionState,
  } = useChatsProvider();

  const handleClick = (callback: () => void) => () => {
    callback();
    handleSelectMessage('');
  };

  // TODO try move this action in ContextMenu and move all actions from this component into ContextMenu?
  //  - then can add ability to count min-height for the list.
  /** Handle Delete */
  const handleDeleteClick = (id: string | null) => {
    if (!id) return;
    handleConfirmPopup({
      title: 'Delete',
      content: 'Are sure you want to delete this message?',
      cancelText: 'Cancel',
      centered: true,
      okText: 'Delete',
      okButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => handleMessageDelete({ messageId: id }),
      maskClosable: true,
    });
  };

  const handleEditClick = handleClick(() => {
    handleMessageActionState({ message: messageItem, mode: 'edit' });
  });

  const options: OptionsList[] = useMemo(() => {
    const allOptions = [
      {
        key: 'reply',
        label: 'Reply',
        icon: <IconReply />,
        onClick: handleClick(() => (
          handleMessageActionState({ message: messageItem, mode: 'reply' })
        )),
      },
      {
        key: 'copy',
        label: 'Copy',
        icon: <IconContentCopy />,
        onClick: handleClick(() => copyToClipboard(messageItem.message)),
      },
      {
        key: 'edit',
        label: 'Edit',
        icon: <IconPencilEdit />,
        onClick: handleEditClick,
      },
      /* {
        key: 'forward', label: 'Forward', icon: <IconForward />, onClick: handleClick(() => console.log('forward')),
      }, */
      {
        key: 'delete',
        label: 'Delete',
        icon: <IconDelete />,
        onClick: () => handleDeleteClick(messageItem.id),
      },
    ];

    const onlyOwnMessagesKeys = new Set(['edit', 'delete', 'forward']);

    /** message alignment 'left' means that message not from current user */
    return allOptions.filter((option) => !(
      align === 'left' && onlyOwnMessagesKeys.has(option.key)
    ));
  }, [selectedMessageId, align]);

  return (
    <div
      className={clsx(styles.wrapper, className, {
        [styles.visible]: visible,
        [styles.right]: align === 'right',
      })}
    >
      {options.map(({ label, icon, onClick }, index, arr) => (
        <div
          role="button"
          tabIndex={-1}
          aria-label="actions"
          key={label}
          className={clsx(styles.actionButton, {
            [styles.divider]: index < arr.length - 1,
          })}
          onClick={onClick}
        >
          <span>{label}</span>
          <span>{icon}</span>
        </div>
      ))}
    </div>
  );
}

export default ActionsMenu;
