import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { config } from '@triare/auth-redux';
// import Simple from '../../../components/Layout/Simple';
// import { useAuthState } from '../../../store/auth';
import { Tabs, TabsProps } from 'antd';
import SignInContent from '../../../components/Pages/Auth/SignIn';
import Layout from '../../../components/Layout';
import EmailAndPassword from '../../../components/Pages/Auth/SignIn/EmailAndPassword';
import PhoneAndPassword from '../../../components/Pages/Auth/SignIn/PhoneAndPassword';
import UsernameAndPassword from '../../../components/Pages/Auth/SignIn/UsernameAndPassword';
import Phone from '../../../components/Pages/Auth/SignIn/Phone';

const { displayName } = config;

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Email & Password',
    children: <SignInContent content={EmailAndPassword} />,
  },
  {
    key: '2',
    label: 'Phone',
    children: <SignInContent content={Phone} />,
  },
  {
    key: '3',
    label: 'Phone & Password',
    children: <SignInContent content={PhoneAndPassword} />,
  },
  {
    key: '4',
    label: 'Username & Password',
    children: <SignInContent content={UsernameAndPassword} />,
  },
];

function SignIn(): React.ReactNode {
  document.title = `${displayName}: Sign in`;

  return (
    <Layout>
      <Tabs
        destroyInactiveTabPane
        defaultActiveKey="1"
        centered
        items={items}
        style={{ padding: '0 16px 16px 16px' }}
      />
    </Layout>
  );
}

export default SignIn;
