import { io, Socket } from 'socket.io-client';
import { AnyObject } from '@triare/auth-redux/src/saga/common';
import {
  CreateMessagePayload, IChat, Message, AddReactionPayload, ReceiveReactionPayload, UpdateMessagePayload,
  DeleteMessagePayload, DeleteMessageResponse,
} from '../hooks/api/chats';

type Options = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export interface ClientToServerEvents extends AnyObject {
  foo: (message: string) => void;
  message: (message: CreateMessagePayload) => void;
  'receive-message': (message: Message) => void;

  'update-message': (message: UpdateMessagePayload) => void;
  'receive-update-message': (message: Message) => void;

  'delete-message': (payload: DeleteMessagePayload) => void;
  'receive-delete-message': (res: DeleteMessageResponse) => void;
  'receive-chat': (chat: IChat) => void;
  reaction: (props: AddReactionPayload) => void;
  'receive-reaction': (props: ReceiveReactionPayload) => void;
  'online-users': (userIds: string[]) => void;
  // [key: string]: any;
}

export class SocketProvider {
  private connect?: Socket;

  get socket(): Socket<ClientToServerEvents> | undefined {
    if (!this.connect) {
      console.error('Socket error: First initialize the socket connection.');
    }

    return this.connect;
  }

  init(token: string, options: Options = {}) {
    if (this.connect) {
      return;
    }

    this.connect = io(process.env.REACT_APP_SOCKET_CHAT || '', {
      auth: {
        ...options,
        token,
      },
      transports: ['websocket', 'polling'],
    });

    this.connect.on('connect', () => {
      console.log('connect success');
    });

    this.connect.on('disconnect', (err) => {
      console.log('disconnect >', err);
    });

    this.connect.on('connect_error', (err) => {
      console.log('error >', err);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reconnect(token: string, options: Options = {}) {
    if (!this.connect) {
      this.init(token, options);

      return;
    }

    Object.assign(this.connect.auth, {
      ...options,
      token,
    });

    this.connect = this.connect.disconnect().connect();
  }
}

export default new SocketProvider();
