import React, { useEffect } from 'react';
import ContactRow from '../ContactRow';
import { useChatsProvider } from '../../../../context/chats';

interface UsersListProps {
  onRow: (id: string) => void;
  ignoreIds?: string[];
}

function ContactsList({ onRow, ignoreIds = [] }: UsersListProps) {
  const { users, handleFetchUsers } = useChatsProvider();

  useEffect(() => {
    if (users.length === 0) {
      handleFetchUsers(ignoreIds);
    }
  }, []);

  return (
    <>
      {users.map((user) => (
        <ContactRow key={user.id} user={user} onClick={() => onRow(user.id)} />
      ))}
    </>
  );
}

export default ContactsList;
