import {
  FetchGet, PagingDataResponse, PagingParams, useFetchGet,
} from '../fetch';
import { User } from '../../store/auth';

export interface UsersGetParams extends PagingParams {
  fullName?: string;
  orderByColumn?: 'username' | 'firstName';
}

export const useUsersGet = (): FetchGet<PagingDataResponse<User>, UsersGetParams> => useFetchGet(
  'users',
  { autoStart: false, startStateLoading: false },
);

export default {
  useUsersGet,
};
