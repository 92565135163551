import { IChat } from '../../../hooks/api/chats';

export interface IRoomsDataStorage {
  [key: string]: ISetStorageData;
}

export interface ISetStorageData {
  message: string;
}

export const setChatItemInStorage = (data: ISetStorageData, roomId: string) => {
  const prevData: IRoomsDataStorage = JSON.parse(localStorage.getItem('chats_data') || '{}');

  localStorage.setItem('chats_data', JSON.stringify({
    ...prevData,
    [roomId]: data,
  }));
};

export const getChatItemFromStorage = (roomId: string) => {
  const item = localStorage.getItem('chats_data');

  const roomData: ISetStorageData | undefined = JSON.parse(item || '{}')?.[roomId];

  return roomData;
};

export const getAllChatFromStorage = (): IRoomsDataStorage => JSON.parse(localStorage.getItem('roomsData') || '{}');

/** Util to sort chats by last message time */
export const sortChatsByLastMssgTime = (chats: IChat[] | undefined) => {
  if (!chats?.length) return [];

  return chats.sort((a, b) => {
    // Handle empty messages and sort by presence first
    if (!a.messages && !b.messages) return 0; // Both chats have no messages
    if (!a.messages) return 1; // Chat A has no messages, push it to the end
    if (!b.messages) return -1; // Chat B has no messages, prioritize it

    // Get the last message from each chat
    const messageA = a.messages[a.messages.length - 1]; // Each chat have only last mssg, so can use like .[0]
    const messageB = b.messages[b.messages.length - 1];

    // Convert createdAt strings to Date objects
    const dateA = new Date(messageA?.createdAt);
    const dateB = new Date(messageB?.createdAt);

    // Descendant sort by message date (newest first)
    return dateB.getTime() - dateA.getTime();
  });
};
