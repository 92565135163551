import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import clsx from 'clsx';

import styles from './index.module.scss';

interface CollapseTriggerProps extends React.PropsWithChildren, React.HTMLProps<HTMLDivElement> {
  onClick: () => void;
}
export function CollapseTrigger({ onClick, children, ...rest }: CollapseTriggerProps) {
  return (
    <div role="button" tabIndex={-1} className={styles.triggerButton} onClick={onClick} {...rest}>
      {children}
    </div>
  );
}

interface CollapsePanelProps extends React.PropsWithChildren {
  open: boolean;
  /** Content will have overflow-y if it takes more height than given value */
  maxHeight?: number;
  header?: React.ReactNode;
  transitionDelay?: number; // ms
}

export default function CollapsePanel({
  open, children, maxHeight = undefined, header, transitionDelay,
}: CollapsePanelProps) {
  const ref = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);

  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (ref.current) {
      // If header provided, then include it in calculation
      if (headerRef.current) {
        setContentHeight(ref.current.clientHeight + headerRef.current.clientHeight);
      } else {
        setContentHeight(ref.current.clientHeight);
      }
    }
  }, [children, header]);

  /** Calculates max-height including header, if header provided */
  const contentMaxHeight = useMemo(() => {
    if (headerRef.current && maxHeight) {
      return (
        maxHeight - headerRef.current.getBoundingClientRect().height
      );
    }

    return maxHeight;
  }, [header, maxHeight]);

  return (
    <div
      className={styles.collapse}
      style={{
        height: open ? contentHeight : '0px',
        transitionDelay: transitionDelay ? `${transitionDelay}ms` : undefined,
      }}
    >
      {header ? (
        <div ref={headerRef}>
          {header}
        </div>
      ) : null}
      <div
        ref={ref}
        className={clsx({ [styles.scroll]: !!maxHeight })}
        style={{ maxHeight: contentMaxHeight }}
      >
        {children}
      </div>
    </div>
  );
}
