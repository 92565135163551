import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Alert } from 'antd';
import { Error } from '@triare/auth-redux/dist/saga/common';
import { AlertProps } from 'antd/es/alert';
import { AxiosError } from 'axios';
import { RootState } from '../../../../../../../store';
import { actions, moduleName } from '../../../../../../../store/auth';
import { getMessageInError } from '../../../../../../../hooks/fetch';

import styles from './index.module.scss';

export interface AlertErrorProps extends AlertProps {
  disable?: boolean;
  error?: AxiosError<Error> | null;
}

function AlertError({ error = null, disable = false, ...props }: AlertErrorProps): React.ReactNode {
  const dispatch = useDispatch();

  if (disable === false && error) {
    return (
      <Alert
        type="error"
        message={getMessageInError(error)}
        closable
        showIcon
        className={styles.alert}
        onClose={() => dispatch({
          type: actions.signInErrorClear.toString(),
        })}
        {...props}
      />
    );
  }

  return null;
}

export default connect((state: RootState) => ({
  error: state[moduleName].signIn.error,
}))(AlertError);
