import React, {
  createContext, ReactNode, useContext, useEffect, useMemo, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { UsersGetParams, useUsersGet } from '../hooks/api/users';
import { User } from '../store/auth';
import { PagingDataResponse } from '../hooks/fetch';
import { useSimpleModal, UseSimpleModalProps } from '../components/Common/Modal/Simple';
import { Message } from '../hooks/api/chats';

interface ChatsProviderProps {
  selectedChatId: string | null,
  selectedMessageId: string | null,
  setSelectedChatId: React.Dispatch<React.SetStateAction<string | null>>,
  handleSelectMessage: (messageId: string) => void,

  chatsLoading: boolean;
  messagesLoading: boolean;
  handleChatsLoading: (loading: boolean) => void;
  handleMessagesLoading: (loading: boolean) => void;
  users: User[],
  handleFetchUsers: (ignoreIds?: string[], params?: UsersGetParams) => Promise<PagingDataResponse<User> | null>;
  openAdditions: OpenAdditions;
  handleOpenAdditions: ({ open, mode }: OpenAdditions) => void;

  handleConfirmPopup: (props?: UseSimpleModalProps) => void,

  messageActionState: MessageActionState,
  handleMessageActionState: (state: MessageActionState | null) => void,
}

interface OpenAdditions { open: boolean, mode?: AdditionsType }
export type AdditionsType = 'emoji' | 'sticker' | 'files';

interface MessageActionState {
  message: Message | null,
  mode: 'edit' | 'reply' | null,
}

const defaultValue: ChatsProviderProps = {
  selectedChatId: '',
  selectedMessageId: '',
  setSelectedChatId: () => undefined,
  handleSelectMessage: () => undefined,

  chatsLoading: false,
  messagesLoading: false,
  handleChatsLoading: () => undefined,
  handleMessagesLoading: () => undefined,
  users: [],
  handleFetchUsers: async () => null,
  openAdditions: { open: false, mode: 'emoji' },
  handleOpenAdditions: () => undefined,

  handleConfirmPopup: () => undefined,

  messageActionState: { message: null, mode: null },
  handleMessageActionState: () => undefined,
};

export const ChatsContext = createContext<ChatsProviderProps>(defaultValue);

function ChatsProvider({ children }: { children: ReactNode }) {
  const { open, contextHolder } = useSimpleModal();

  const [chatsLoading, setGlobalLoading] = useState<boolean>(false);
  const [messagesLoading, setMessagesLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const usersGet = useUsersGet(); /** On real project replace with 'Contacts' */

  const handleChatsLoading = (payload: boolean) => { setGlobalLoading(payload); };
  const handleMessagesLoading = (payload: boolean) => { setMessagesLoading(payload); };

  useEffect(() => {
    handleChatsLoading(usersGet.loading);
  }, [usersGet.loading]);

  const handleConfirmPopup = (props?: UseSimpleModalProps) => open(props);

  const [searchParams, _] = useSearchParams();

  /** Selected chat & Context-menu:message */
  const [selectedChatId, setSelectedChatId] = useState(searchParams.get('id'));
  const [selectedMessageId, setSelectedMessageId] = useState<string>('');

  const handleSelectMessage = (messageId: string) => {
    setSelectedMessageId(messageId);
  };

  useEffect(() => {
    setSelectedChatId(searchParams.get('id'));
    handleMessageActionState(null);
  }, [searchParams.get('id')]);

  /** Message editing/replying */
  const [messageActionState, setMessageActionState] = useState<MessageActionState>(defaultValue.messageActionState);

  const handleMessageActionState = (state: MessageActionState | null) => {
    if (state === null) {
      setMessageActionState(defaultValue.messageActionState);

      return;
    }

    setMessageActionState(state);
  };

  /** Right sidebar */
  const [openAdditions, setOpenAdditions] = useState(defaultValue.openAdditions);

  const handleOpenAdditions = (props: OpenAdditions) => {
    setOpenAdditions((prevState) => ({ ...prevState, ...props }));
  };

  const handleFetchUsers = async (ignoreIds?: string[], params?: UsersGetParams) => {
    const newParams: UsersGetParams = { orderByColumn: 'username', pageSize: 100, ...params };
    // TODO change to 'firstName' when backend will add sort by firstName

    const usersData = await usersGet.fetch(newParams)
      .then((res) => {
        if (Array.isArray(res?.data)) {
          setUsers(res.data.filter((user) => !(ignoreIds || []).includes(user.id)));
        }

        return res;
      });

    return usersData;
  };

  const memoizedValue = useMemo(() => ({
    selectedChatId,
    setSelectedChatId,
    selectedMessageId,
    handleSelectMessage,

    chatsLoading,
    handleChatsLoading,
    messagesLoading,
    handleMessagesLoading,
    users,
    handleFetchUsers,
    openAdditions,
    handleOpenAdditions,

    handleConfirmPopup,

    messageActionState,
    handleMessageActionState,
  }), [
    selectedChatId, setSelectedChatId, selectedMessageId, handleSelectMessage,
    chatsLoading, handleChatsLoading, messagesLoading, handleMessagesLoading,
    users, handleFetchUsers, openAdditions, handleOpenAdditions,
    handleConfirmPopup, messageActionState, handleMessageActionState,
  ]);

  return (
    <ChatsContext.Provider value={memoizedValue}>
      {contextHolder}
      {children}
    </ChatsContext.Provider>
  );
}

export default ChatsProvider;

export const useChatsProvider = (): ChatsProviderProps => useContext(ChatsContext);
