import React from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { EditOutlined } from '@ant-design/icons';
import { formatChatTime } from '../utils';
import { Message } from '../../../../hooks/api/chats';

import styles from './index.module.scss';

interface TimeIndicatorProps extends React.HTMLAttributes<HTMLSpanElement> {
  message: Message | undefined;
  displayMode?: 'message' | 'chatsList';
}

function TimeIndicator({
  message, className, displayMode = 'chatsList', ...rest
}: TimeIndicatorProps) {
  if (!message) {
    return (
      <span className={clsx(styles.timeIndicator, className)} />
    );
  }
  const { createdAt, updatedAt } = message;

  const createdTime = dayjs(createdAt);
  const updatedTime = dayjs(updatedAt);

  const isEdited = updatedTime && !createdTime.isSame(updatedTime);
  // const displayTime = isEdited ? updatedTime : createdTime;

  const getDisplayTime = () => {
    if (displayMode === 'chatsList') {
      return formatChatTime(isEdited ? updatedTime : createdTime);
    }

    if (displayMode === 'message') {
      return isEdited ? (
        <span>
          <EditOutlined style={{ marginRight: 4 }} />
          {updatedTime.format('hh:mm a')}
        </span>
      ) : createdTime.format('hh:mm a');
    }

    return '';
  };

  return (
    <span
      className={clsx(styles.timeIndicator, className, 'clip')}
      {...rest}
    >
      {getDisplayTime()}
    </span>
  );
}

export default TimeIndicator;
