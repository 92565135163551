import React, { useRef } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { Popover } from 'react-tiny-popover';
import { ReactionEmojisEnum } from '../../../../enums/chats';
import { Message } from '../../../../hooks/api/chats';
import { User } from '../../../../store/auth';
import ContextMenu from './ContextMenu';
import { useChatsProvider } from '../../../../context/chats';

import styles from '../index.module.scss';
import TimeIndicator from '../TimeIndicator';
import { getUserDisplayName } from '../utils';

interface ChatMessageProps {
  messageItem: Message;
  user: User;
  single?: boolean;
}

function ChatMessage({
  messageItem,
  user,
  single,
}: ChatMessageProps) {
  const { selectedMessageId, handleSelectMessage } = useChatsProvider();
  const {
    message, user: messageUser, id, createdAt, reactions, chatId,
  } = messageItem;
  const messageRef = useRef<HTMLDivElement | null>(null);
  const boundaryElement = document.getElementById('messagesList');

  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault();
    handleSelectMessage(id);
  };

  const longPressTimeout = useRef<NodeJS.Timeout | null>(null);
  const touchStartY = useRef<number | null>(null);

  const handleTouchStart = (event: React.TouchEvent<HTMLDivElement>) => {
    // event.preventDefault();
    touchStartY.current = event.touches[0].clientY;
    longPressTimeout.current = setTimeout(() => {
      handleSelectMessage(id);
    }, 600); // 600ms for long press
  };

  const handleTouchMove = (event: React.TouchEvent<HTMLDivElement>) => {
    if (touchStartY.current !== null) {
      const currentY = event.touches[0].clientY;

      if (Math.abs(currentY - touchStartY.current) > 15 // threshold for detecting scroll
        && longPressTimeout.current) {
        clearTimeout(longPressTimeout.current);
        longPressTimeout.current = null;
      }
    }
  };

  const handleTouchEnd = () => {
    if (longPressTimeout.current) {
      clearTimeout(longPressTimeout.current);
      longPressTimeout.current = null;
    }
    touchStartY.current = null;
  };

  // 'Do not define components during render...', so this element in const
  const contextMenuElement = (
    <ContextMenu
      messageItem={messageItem}
      user={user}
      align={messageUser?.id === user?.id ? 'right' : 'left'}
    />
  );

  return (
    <div
      id={id}
      key={id || crypto.randomUUID()}
      className={clsx(
        styles.message,
        { [styles.right]: messageUser?.id === user?.id },
      )}
      onContextMenu={handleRightClick}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      ref={messageRef}
    >
      <div
        className={styles.wrapper}
      >
        {messageUser.id !== user?.id ? (
          <div className={styles.image}>
            <div className={styles.avatar} />
          </div>
        ) : null}

        {/** Wrapping message into popover, to show ContextMenu around message */}
        <Popover
          isOpen={selectedMessageId === id}
          padding={8}
          positions={['top', 'bottom', 'right', 'left']} // Change order to change priority of positions
          align={messageUser?.id === user?.id ? 'end' : 'start'}
          transformMode="relative"
          onClickOutside={() => handleSelectMessage('')}
          clickOutsideCapture
          /** Positioning item in boundary element */
          reposition
          boundaryInset={2}
          boundaryElement={boundaryElement || undefined} // using without ref, with just ID
          // boundaryElement={containerRef.current || undefined} // Renders popover in this element // TODO remove?!
          /** Content props */
          content={contextMenuElement}
        >
          <div className={clsx(styles.messageInfo, { [styles.selectedMessage]: selectedMessageId === id })}>
            <div className={styles.userName}>
              {single ? null : (<span>{getUserDisplayName(messageUser)}</span>)}
            </div>
            {message}

            <TimeIndicator displayMode="message" message={messageItem} className={styles.time} />

            {/* TODO update layouts. need to make max 3 in a row. */}
            {(reactions || []).length > 0 ? (
              <div className={styles.reactions}>
                {reactions?.map((item) => <span key={item.reaction}>{ReactionEmojisEnum[item.reaction]}</span>)}
              </div>
            ) : null}
          </div>
        </Popover>

      </div>
    </div>

  );
}

export default ChatMessage;
