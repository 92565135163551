import React from 'react';
import clsx from 'clsx';
import { User } from '../../../../store/auth';
import { getUserDisplayName } from '../utils';

import styles from './index.module.scss';

interface ContactRowProps extends React.PropsWithChildren {
  onClick?: () => void;
  user?: User;
  className?: string;
  isRowActive?: boolean;
  variant?: 'head' | 'list';
}

function ContactRow({
  children,
  user,
  onClick = () => undefined,
  className,
  isRowActive = false,
  variant = 'list',
}: ContactRowProps) {
  return (
    <div
      className={clsx(styles.user, {
        [styles.activeChat]: isRowActive,
        [styles.inHead]: variant === 'head',
      }, className)}
      role="button"
      tabIndex={-1}
      onClick={onClick}
    >
      <div className={styles.image}>
        <div className={styles.avatar} />
      </div>
      <div className={styles.info}>
        {children || (<span className="clip">{user ? getUserDisplayName(user) : ''}</span>)}
      </div>
    </div>
  );
}

export default ContactRow;
