import React from 'react';
import ChatContent from '../../Common/Chat';

import styles from './index.module.scss';

export default function Chat(): React.ReactNode {
  return (
    <div className={styles.content}>
      <ChatContent />
    </div>
  );
}
