import { User } from '../../store/auth';
import {
  DefaultFetchError,
  FetchCreate,
  FetchGet, FetchGetId, FetchSuccess, useFetchCreate, useFetchDelete, useFetchGet, useFetchGetId,
} from '../fetch';

export interface IChat {
  id: string;
  single: boolean;
  name?: string;
  user: User;
  logo?: {
    id: string;
    originalName: string;
    mimeType: string;
    createdAt: string;
  }
  members: Member[];
  messages?: Message[];
}

export type ChatUserRole = 'owner' | 'admin' | 'member';
export interface Member {
  id: string;
  role: ChatUserRole;
  user: User;
}

export interface Message {
  id: string;
  user: User;
  message: string;
  createdAt: string;
  updatedAt: string;
  chatId?: string; // Only in response from socket. // TODO ask backend leave to only one prop
  chat?: IChat; // Only in response from socket.
  reactions?: { reaction: ReactionType }[]; // TODO looks like there should be user too, to detect whom reacted
}

export const useChatsGet = (): FetchGet<IChat[], undefined> => useFetchGet(
  'chats',
  { autoStart: false, startStateLoading: false },
);

/** Handle chats */
export interface CreateChatPayload {
  members: string[];
  single: boolean;
  logo?: string;
  name?: string;
}

export interface AddChatMemberPayload {
  members: string[];
}

export const useChatCreate = (): FetchCreate<IChat, DefaultFetchError, CreateChatPayload> => (
  useFetchCreate('chats')
);
export const useAddChatMembers = (): FetchCreate<FetchSuccess, DefaultFetchError, AddChatMemberPayload> => (
  useFetchCreate('chats') // '/{chatId}'
);
export const useDeleteChatMembers = (id?: string) => useFetchDelete<undefined, DefaultFetchError, string>('chats', id);

/** Handle messages */
export interface CreateMessagePayload {
  message: string;
  chat: string;
}

export interface UpdateMessagePayload {
  message: string;
  id: string;
}

export interface DeleteMessagePayload {
  id: string;
}

export interface DeleteMessageResponse {
  id: string;
  chat: { id: string };
}

export type ReactionType = 'like' | 'dislike' | 'smile' | 'cry' | 'heart';
export interface AddReactionPayload {
  reaction: ReactionType;
  messageId: string;
  chat?: string;
}

export interface ReceiveReactionPayload {
  reaction: ReactionType;

  chatId: string;
  id: string;
  message: Message;
  user: User;
}

export const useChatIdMessages = (id?: string): FetchGetId<Message[], DefaultFetchError> => useFetchGetId(
  'chats',
  id,
  { autoStart: false, startStateLoading: false },
);

// These requests unused, we using socket.emit(...
export const useChatMessageCreate = (): FetchCreate<Message, DefaultFetchError, CreateMessagePayload> => (
  useFetchCreate('chats/messages')
);

export const useMessageReaction = (): FetchCreate<FetchSuccess, DefaultFetchError, AddReactionPayload> => (
  useFetchCreate('chats')
);
