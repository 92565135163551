import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { enumToOptionsArray } from '../../../../utils';
import { ReactionEmojisEnum } from '../../../../enums/chats';
import { ReactionType } from '../../../../hooks/api/chats';
import CollapsePanel, { CollapseTrigger } from '../../CollapsePanel';
import { IconPlus } from '../../Icon';

import styles from './index.module.scss';

const options = (enumToOptionsArray(ReactionEmojisEnum) || []).slice(0, 5);
const extendedOptions = (enumToOptionsArray(ReactionEmojisEnum) || []).slice(5);

interface ReactionItemProps {
  value: string;
  label: string;
  onReaction: (val: ReactionType) => void;
  disabled?: boolean;
}
function ReactionItem({
  value, label, disabled, onReaction,
}: ReactionItemProps) {
  return (
    <div
      role="button"
      tabIndex={-1}
      aria-label="select-reaction"
      key={value}
      className={clsx(styles.reaction, { [styles.disabled]: disabled })}
      onClick={disabled
        ? undefined
        : () => onReaction(value as ReactionType)}
    >
      {label}
    </div>
  );
}

interface ReactionSelectProps {
  onReaction: (reaction: ReactionType) => void;
  disabledReactions: ReactionType[];
  align?: 'left' | 'right';
  className?: string;
  visible: boolean;
  expandProps?: {
    defaultExpanded: boolean;
    onExpandedChange: (expanded: boolean) => void;
    maxHeight?: number;
  },
}

function ReactionSelect({
  onReaction,
  disabledReactions,
  className,
  visible,
  align = 'left',
  expandProps = { defaultExpanded: false, onExpandedChange: () => undefined, maxHeight: undefined },
}: ReactionSelectProps) {
  const [expanded, setExpanded] = useState(!!expandProps?.defaultExpanded);

  const toggleExpanded = () => {
    setExpanded((prevState) => !prevState);
  };

  /** Handling external state */
  useEffect(() => {
    if (expandProps) {
      expandProps.onExpandedChange(expanded);
    }
  }, [expanded]);

  /** Search */
  const [search, setSearch] = useState('');

  const filteredOptions = extendedOptions.filter(({ value }) => (
    value.replaceAll('_', ' ').toLowerCase().includes(search.toLowerCase())));

  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.gridRow, styles.selectRow, className, {
        [styles.visible]: visible,
        [styles.right]: align === 'right',
      })}
      >
        {options.map(({ value, label }) => (
          <ReactionItem
            key={value}
            value={value}
            label={label}
            disabled={disabledReactions.includes(value as ReactionType)}
            onReaction={(val) => onReaction(val)}
          />
        ))}
        <CollapseTrigger onClick={toggleExpanded} style={{ width: 30, height: 30 }}>
          <IconPlus />
        </CollapseTrigger>
      </div>

      <CollapsePanel
        open={expanded}
        maxHeight={expandProps?.maxHeight || 154} // 154 to match height of ActionsMenu
        header={(
          <Input
            placeholder="Search"
            prefix={<SearchOutlined />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        )}
      >
        <div className={clsx(styles.gridRow, styles.extendedReactions)}>
          {filteredOptions.map(({ value, label }) => (
            <ReactionItem
              key={value}
              value={value}
              label={label}
              disabled={disabledReactions.includes(value as ReactionType)}
              onReaction={onReaction}
            />
          ))}
        </div>
      </CollapsePanel>
    </div>

  );
}

export default ReactionSelect;
