import React from 'react';
import { Divider } from 'antd';
import styles from './index.module.scss';
import FormImage from './FormImageUpload';

export default function Home(): React.ReactNode {
  return (
    <div className={styles.content}>
      home
      <Divider>Image upload form:</Divider>
      <FormImage />
    </div>
  );
}
