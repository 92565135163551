import React, { useEffect, useState } from 'react';
import { Form as AntdForm, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  signInByPhone as signInByPhoneAction,
  SignInByPhoneAction,
} from '@triare/auth-redux/dist/saga/auth/signIn';
import { Action } from '@triare/auth-redux';
import { Error, AnyObject } from '@triare/auth-redux/dist/saga/common';
import {
  createRulesForAntd,
  validationSignInPhoneAndPassword,
} from '../../../../../utils/validations';
import Form from '../Form';
import PhoneNumberInput from '../../../../Common/PhoneNumberInput';
import { RootState } from '../../../../../store';
import { moduleName } from '../../../../../store/auth';
import { ContentProps } from '../index';
import Footer from '../Footer';
import Separator from '../../../../Common/Form/Separator';
import ButtonGoogle from '../Form/ButtonGoogle';
import ButtonApple from '../Form/ButtonApple';
import ButtonFacebook from '../Form/ButtonFacebook';

const validationRules = createRulesForAntd(validationSignInPhoneAndPassword);

interface PhoneAndPasswordProps extends ContentProps {
  authorized: boolean
  response: AnyObject | null
  error: Error | null
  signIn: (payload: SignInByPhoneAction) => Action
}

function PhoneAndPassword({
  response, error, signIn, onSubmit,
}: PhoneAndPasswordProps): React.ReactNode {
  const [signInData, setSignInData] = useState<SignInByPhoneAction | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!error && response && onSubmit && signInData) {
      onSubmit(signInData);
    }
  }, [response, error, onSubmit, signInData]);

  return (
    <>
      <Form<SignInByPhoneAction>
        validationRules={validationRules}
        validateSubmit={({ phone, password }) => !phone || !password}
        signIn={(props) => {
          const data: SignInByPhoneAction = {
            ...props,
            // _query: {
            //   lang: 'en',
            // },
          };

          setSignInData(data);
          signIn(data);
        }}
        name="signInPhoneAndPassword"
      >
        <AntdForm.Item name="phone" label={`${t('phone')} *`} rules={[validationRules]}>
          <PhoneNumberInput
            placeholder="321567926"
            // placeholder={t('phone') || 'Phone'}
            size="large"
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="password"
          label={`${t('password')} *`}
          rules={[validationRules]}
        >
          <Input.Password placeholder="Password" size="large" />
        </AntdForm.Item>
      </Form>

      <Separator />

      <AntdForm.Item>
        <ButtonGoogle onSubmit={onSubmit} />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonApple onSubmit={onSubmit} />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonFacebook onSubmit={onSubmit} />
      </AntdForm.Item>

      <Footer />
    </>
  );
}

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  response: state[moduleName].signIn.response,
  error: state[moduleName].signIn.error,
}), {
  signIn: signInByPhoneAction,
})(PhoneAndPassword);
