import React from 'react';
import { config } from '@triare/auth-redux';
import InformationContent from '../../../../components/Pages/Auth/SignUp/Information';
import Layout from '../../../../components/Layout';

const { displayName } = config;

function Information(): React.ReactNode {
  document.title = `${displayName}: Information`;

  return (
    <Layout>
      <InformationContent />
    </Layout>
  );
}

export default Information;
