import React from 'react';
import clsx from 'clsx';
import { IChat } from '../../../../hooks/api/chats';
import { User } from '../../../../store/auth';
import { IconDockToLeft, IconDockToRight } from '../../Icon';
import { useChatsProvider } from '../../../../context/chats';
import ActionsPopup from './ActionsPopup';
import { getUserDisplayName } from '../utils';

import mainChatStyles from '../index.module.scss';
import styles from './index.module.scss';

interface ChatHeadProps {
  currentUser: User | undefined;
  chatData: IChat | undefined;
  actionsProps: {
    openList: boolean,
    setOpenList: (open: boolean) => void,
    isMobile: boolean,
    isDesktop: boolean,
  },
}
function ChatHead({ currentUser, chatData, actionsProps }: ChatHeadProps) {
  const { openAdditions, handleOpenAdditions } = useChatsProvider();
  const {
    openList, setOpenList, isDesktop, isMobile,
  } = actionsProps;

  /** Left action btn */
  /* TODO check this sample. second ternary is useless. same component renders! */
  const leftSidebarAction = (
    // eslint-disable-next-line no-nested-ternary
    !openList ? (isMobile ? (
      <IconDockToLeft
        className={clsx(mainChatStyles.icon, mainChatStyles.left)}
        onClick={() => {
          setOpenList(true);

          if (!isDesktop) {
            handleOpenAdditions({ open: false });
          }
        }}
      />
    ) : (
      <IconDockToLeft
        className={clsx(mainChatStyles.icon, mainChatStyles.left)}
        onClick={() => {
          setOpenList(true);

          if (!isDesktop) {
            handleOpenAdditions({ open: false });
          }
        }}
      />
    )) : null
  );

  /** If chat not selected yet - show only action to open list with contacts */
  if (!chatData) return leftSidebarAction;

  return (
    <>
      <div className={styles.chatInfo}>
        <div className={styles.infoContent}>
          <div className={styles.image}>
            <div className={styles.avatar} />
          </div>

          <div className={styles.titleGrid}>
            <span className={clsx('clip', styles.title)}>
              {chatData.name || (chatData.members || [])
                .filter((member) => member.user?.id !== currentUser?.id)
                .map((member) => getUserDisplayName(member.user))
                .join(', ')}
            </span>
            <span className={clsx('clip', styles.subtitle)}>
              subtitle
            </span>
          </div>
        </div>

        <div className={clsx(styles.actionsRight)}>
          <ActionsPopup chatId={chatData.id} />
        </div>
      </div>

      {leftSidebarAction}

      {/** Right action btn */}
      {!openAdditions.open ? (
        <IconDockToRight
          className={mainChatStyles.icon}
          onClick={() => {
            handleOpenAdditions({ open: true });

            if (!isDesktop) {
              setOpenList(false);
            }
          }}
        />
      ) : null}
    </>
  );
}

export default ChatHead;
