import React from 'react';
import { config } from '@triare/auth-redux';
import ForgotPasswordContent from '../../../components/Pages/Auth/ForgotPassword';
import Layout from '../../../components/Layout';

const { displayName } = config;

export default function ForgotPassword(): React.ReactNode {
  document.title = `${displayName}: Forgot password`;

  return (
    <Layout>
      <ForgotPasswordContent />
    </Layout>
  );
}
