import React from 'react';

/* eslint-disable max-len */

interface Svg extends React.SVGProps<SVGSVGElement> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export function Svg({
  children,
  className = '',
  viewBox = '0 0 24 24',
  style = {},
  ...props
}: Svg): React.ReactNode {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      height="1em"
      width="1em"
      viewBox={viewBox}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      {...props}
    >
      {children}
    </svg>
  );
}

export function IconDockToLeft(props: Partial<Svg>) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <mask id="mask0_599_9869" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_599_9869)">
        <path d="M5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM16 19H19V5H16V19ZM14 19V5H5V19H14Z" />
      </g>
    </Svg>
  );
}

export function IconDockToRight(props: Partial<Svg>) {
  return (
    <IconDockToLeft {...props} style={{ transform: 'scale(-1, 1)', ...(props?.style || {}) }} />
  );
}

export function IconNotificationOff(props: Partial<Svg>) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      {/* <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      <mask id="mask0_1004_8935" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1004_8935)">
        <path
          d="M4.00002 19V17H6.00002V10C6.00002 9.45 6.07086 8.90833 6.21252 8.375C6.35419 7.84167 6.56669 7.33333 6.85002 6.85L8.35003 8.35C8.23336 8.61667 8.14586 8.8875 8.08752 9.1625C8.02919 9.4375 8.00002 9.71667 8.00002 10V17H14.2L1.40002 4.2L2.80002 2.8L21.2 21.2L19.8 22.6L16.15 19H4.00002ZM18 15.15L16 13.15V10C16 8.9 15.6084 7.95833 14.825 7.175C14.0417 6.39167 13.1 6 12 6C11.5667 6 11.15 6.06667 10.75 6.2C10.35 6.33333 9.98336 6.53333 9.65003 6.8L8.20003 5.35C8.53336 5.08333 8.89169 4.85 9.27502 4.65C9.65836 4.45 10.0667 4.3 10.5 4.2V3.5C10.5 3.08333 10.6459 2.72917 10.9375 2.4375C11.2292 2.14583 11.5834 2 12 2C12.4167 2 12.7709 2.14583 13.0625 2.4375C13.3542 2.72917 13.5 3.08333 13.5 3.5V4.2C14.8334 4.53333 15.9167 5.2375 16.75 6.3125C17.5834 7.3875 18 8.61667 18 10V15.15ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1959 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0209 21.8042 12.55 22 12 22Z"
          fill="currentColor" // #555555
        />
      </g>
      {/* </svg> */}

    </Svg>
  );
}
export function IconFrontHand(props: Partial<Svg>) {
  return (
    <Svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <mask
        id="mask0_1004_8940"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="currentColor" />
      </mask>
      <g mask="url(#mask0_1004_8940)">
        <path
          d="M12 12V2C12 1.71667 12.0958 1.47917 12.2875 1.2875C12.4792 1.09583 12.7167 1 13 1C13.2833 1 13.5208 1.09583 13.7125 1.2875C13.9042 1.47917 14 1.71667 14 2V12H12ZM8 12V3C8 2.71667 8.09583 2.47917 8.2875 2.2875C8.47917 2.09583 8.71667 2 9 2C9.28333 2 9.52083 2.09583 9.7125 2.2875C9.90417 2.47917 10 2.71667 10 3V12H8ZM12.5 23C10.1333 23 8.125 22.175 6.475 20.525C4.825 18.875 4 16.8667 4 14.5V5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4C5.28333 4 5.52083 4.09583 5.7125 4.2875C5.90417 4.47917 6 4.71667 6 5V14.5C6 16.3167 6.62917 17.8542 7.8875 19.1125C9.14583 20.3708 10.6833 21 12.5 21C14.3167 21 15.8542 20.3708 17.1125 19.1125C18.3708 17.8542 19 16.3167 19 14.5V11C18.7167 11 18.4792 11.0958 18.2875 11.2875C18.0958 11.4792 18 11.7167 18 12V16H15C14.45 16 13.9792 16.1958 13.5875 16.5875C13.1958 16.9792 13 17.45 13 18V19H11V18C11 16.9 11.3917 15.9583 12.175 15.175C12.9583 14.3917 13.9 14 15 14H16V4C16 3.71667 16.0958 3.47917 16.2875 3.2875C16.4792 3.09583 16.7167 3 17 3C17.2833 3 17.5208 3.09583 17.7125 3.2875C17.9042 3.47917 18 3.71667 18 4V9.175C18.1667 9.125 18.3292 9.08333 18.4875 9.05C18.6458 9.01667 18.8167 9 19 9H21V14.5C21 16.8667 20.175 18.875 18.525 20.525C16.875 22.175 14.8667 23 12.5 23Z"
          fill="currentColor"
        />
      </g>
    </Svg>
  );
}
export function IconReply(props: Partial<Svg>) {
  return (
    <Svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
      <mask
        id="mask0_1055_4226"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1055_4226)">
        <path
          d="M12.6667 12.6663V9.99967C12.6667 9.44412 12.4722 8.9719 12.0833 8.58301C11.6944 8.19412 11.2222 7.99967 10.6667 7.99967H4.55L6.95 10.3997L6 11.333L2 7.33301L6 3.33301L6.95 4.26634L4.55 6.66634H10.6667C11.5889 6.66634 12.375 6.99134 13.025 7.64134C13.675 8.29134 14 9.07745 14 9.99967V12.6663H12.6667Z"
          fill="#555555"
        />
      </g>

    </Svg>
  );
}
export function IconContentCopy(props: Partial<Svg>) {
  return (
    <Svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
      <mask
        id="mask0_1055_4231"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1055_4231)">
        <path
          d="M6 11.9997C5.63333 11.9997 5.31944 11.8691 5.05833 11.608C4.79722 11.3469 4.66667 11.033 4.66667 10.6663V2.66634C4.66667 2.29967 4.79722 1.98579 5.05833 1.72467C5.31944 1.46356 5.63333 1.33301 6 1.33301H12C12.3667 1.33301 12.6806 1.46356 12.9417 1.72467C13.2028 1.98579 13.3333 2.29967 13.3333 2.66634V10.6663C13.3333 11.033 13.2028 11.3469 12.9417 11.608C12.6806 11.8691 12.3667 11.9997 12 11.9997H6ZM6 10.6663H12V2.66634H6V10.6663ZM3.33333 14.6663C2.96667 14.6663 2.65278 14.5358 2.39167 14.2747C2.13056 14.0136 2 13.6997 2 13.333V3.99967H3.33333V13.333H10.6667V14.6663H3.33333Z"
          fill="#555555"
        />
      </g>
    </Svg>
  );
}
export function IconPencilEdit(props: Partial<Svg>) {
  return (
    <Svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
      <mask
        id="mask0_1055_4236"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1055_4236)">
        <path
          d="M1.33337 15.9997V13.333H14.6667V15.9997H1.33337ZM4.00004 10.6663H4.93337L10.1334 5.48301L9.18337 4.53301L4.00004 9.73301V10.6663ZM2.66671 11.9997V9.16634L10.1334 1.71634C10.2556 1.59412 10.3973 1.49967 10.5584 1.43301C10.7195 1.36634 10.8889 1.33301 11.0667 1.33301C11.2445 1.33301 11.4167 1.36634 11.5834 1.43301C11.75 1.49967 11.9 1.59967 12.0334 1.73301L12.95 2.66634C13.0834 2.78856 13.1806 2.93301 13.2417 3.09967C13.3028 3.26634 13.3334 3.43856 13.3334 3.61634C13.3334 3.78301 13.3028 3.9469 13.2417 4.10801C13.1806 4.26912 13.0834 4.41634 12.95 4.54967L5.50004 11.9997H2.66671Z"
          fill="#555555"
        />
      </g>
    </Svg>
  );
}
export function IconForward(props: Partial<Svg>) {
  return (
    <Svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
      <mask
        id="mask0_1055_4241"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="20" height="20" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1055_4241)">
        <path
          d="M3.99996 15.3337C3.63329 15.3337 3.3194 15.2031 3.05829 14.942C2.79718 14.6809 2.66663 14.367 2.66663 14.0003V6.66699C2.66663 6.30033 2.79718 5.98644 3.05829 5.72533C3.3194 5.46421 3.63329 5.33366 3.99996 5.33366H5.99996V6.66699H3.99996V14.0003H12V6.66699H9.99996V5.33366H12C12.3666 5.33366 12.6805 5.46421 12.9416 5.72533C13.2027 5.98644 13.3333 6.30033 13.3333 6.66699V14.0003C13.3333 14.367 13.2027 14.6809 12.9416 14.942C12.6805 15.2031 12.3666 15.3337 12 15.3337H3.99996ZM7.33329 10.667V3.21699L6.26663 4.28366L5.33329 3.33366L7.99996 0.666992L10.6666 3.33366L9.73329 4.28366L8.66663 3.21699V10.667H7.33329Z"
          fill="#555555"
        />
      </g>

    </Svg>
  );
}
export function IconDelete(props: Partial<Svg>) {
  return (
    <Svg viewBox="0 0 16 16" width="1em" height="1em" {...props}>
      <mask
        id="mask0_1055_4247"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <rect width="16" height="16" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1055_4247)">
        <path
          d="M4.66663 14C4.29996 14 3.98607 13.8694 3.72496 13.6083C3.46385 13.3472 3.33329 13.0333 3.33329 12.6667V4H2.66663V2.66667H5.99996V2H9.99996V2.66667H13.3333V4H12.6666V12.6667C12.6666 13.0333 12.5361 13.3472 12.275 13.6083C12.0138 13.8694 11.7 14 11.3333 14H4.66663ZM11.3333 4H4.66663V12.6667H11.3333V4ZM5.99996 11.3333H7.33329V5.33333H5.99996V11.3333ZM8.66663 11.3333H9.99996V5.33333H8.66663V11.3333Z"
          fill="#555555"
        />
      </g>

    </Svg>
  );
}
export function IconPlus(props: Partial<Svg>) {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <mask
        id="mask0_1070_4517"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_1070_4517)">
        <path d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z" fill="#555555" />
      </g>
    </Svg>
  );
}

export default Svg;
