import React from 'react';
import { config } from '@triare/auth-redux';
import Layout from '../../components/Layout';
import ChatContent from '../../components/Pages/Chat';
import ChatsProvider from '../../context/chats';

const { displayName } = config;

export default function Chat(): React.ReactNode | null {
  document.title = `${displayName}: Chat`;

  return (
    <Layout>
      <ChatsProvider>
        <ChatContent />
      </ChatsProvider>
    </Layout>
  );
}
