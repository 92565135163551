import React, {
  forwardRef, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import clsx from 'clsx';

import styles from './index.module.scss';

const BAR_MIN_HEIGHT = 20;
const SCROLLBAR_TOP = 6;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyProps = any;

interface ScrollProps extends React.HTMLProps<AnyProps> {
  children: React.ReactNode;
  wrapperProps?: React.HTMLProps<AnyProps>;
  scrollToBottomTrigger?: number;
}

function Scroll({
  children,
  className = undefined,
  wrapperProps: {
    className: wrapperClassName,
    ...wrapperProps
  } = { className: undefined },
  scrollToBottomTrigger = 0,
  ...props
}: ScrollProps): React.ReactNode | null {
  const content = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const bar = useRef<HTMLDivElement>(null);
  const [scrollSize, setScrollSize] = useState(0);

  useEffect(() => {
    if (content.current) {
      content.current.style.marginRight = `-${scrollSize}px`;
    }
  }, [scrollSize]);

  /** When height changes scroll to bottom (new message added for example) */
  useEffect(() => {
    if (scrollToBottomTrigger) {
      const container = content.current;

      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: container.scrollHeight > 3000 ? 'instant' : 'smooth',
        });
        // TODO check. If update children after first render [] if component, then it dont sets custom scroll.
        setScrollSize(2);
      }
    }
  }, [scrollToBottomTrigger]);

  useEffect(() => {
    let id: NodeJS.Timeout | undefined;

    function scrollHandler() {
      if (bar.current && content.current) {
        bar.current.style.transition = 'none';
        bar.current.style.opacity = '1';

        if (id) {
          clearTimeout(id);
        }

        id = setTimeout(() => {
          if (bar.current) {
            bar.current.style.transition = '.3s';
            bar.current.style.opacity = '0';
            resize();
          }
        }, 600);

        const coefficient = content.current.offsetHeight / content.current.scrollHeight;
        const height = Math.max(BAR_MIN_HEIGHT, content.current.offsetHeight * coefficient - SCROLLBAR_TOP);
        const coefficient2 = content.current.scrollTop / (content.current.scrollHeight - content.current.offsetHeight);
        const top = ((content.current.offsetHeight - height - SCROLLBAR_TOP) * coefficient2) + SCROLLBAR_TOP / 2;

        bar.current.style.height = `${height}px`;
        bar.current.style.top = `${top}px`;
      }
    }

    if (content.current) {
      content.current.addEventListener('scroll', scrollHandler, false);
    }

    function resize() {
      if (content.current && wrapper.current) {
        setScrollSize(content.current.offsetWidth - wrapper.current.offsetWidth);
      }
    }

    window.addEventListener('resize', resize, false);

    resize();

    return () => {
      if (content.current) {
        content.current.removeEventListener('scroll', scrollHandler);
      }

      window.removeEventListener('resize', resize);
    };
  }, []);

  return (
    <div
      className={styles.scroll}
      style={{
        '--scroll-bar-min-height': `${BAR_MIN_HEIGHT}px`,
      }}
    >
      <div
        {...props}
        className={clsx(className, styles.content)}
        ref={content}
      >
        <div
          {...wrapperProps}
          className={clsx(wrapperClassName, styles.wrapper)}
          ref={wrapper}
        >
          {children}
        </div>
      </div>
      {scrollSize > 1 ? (
        <div className={styles.scrollbar}>
          <div className={styles.bar} ref={bar} />
        </div>
      ) : null}
    </div>
  );
}

export default Scroll;
