import React from 'react';
import {
  DeleteOutlined, EllipsisOutlined,
} from '@ant-design/icons';
import { Button, Popover } from 'antd';
import { IconFrontHand, IconNotificationOff } from '../../../Icon';

import styles from './index.module.scss';

function ActionsPopup({ chatId }: { chatId: string }) {
  const content = (
    <div className={styles.content}>
      {/* TODO add here .map */}
      <Button className="centered-content" icon={<IconNotificationOff style={{ fontSize: 14 }} />}>Sound off</Button>
      <Button className="centered-content" icon={<IconFrontHand style={{ fontSize: 14 }} />}>Block</Button>
      <Button className="centered-content" icon={<DeleteOutlined style={{ fontSize: 14 }} />}>Delete</Button>
    </div>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      placement="bottomRight"
      overlayInnerStyle={{ background: '#eee' }}
      showArrow={false}
    >
      <Button
        type="text"
        className="icon-button"
        onClick={() => console.log('open menu')}
      >
        <EllipsisOutlined style={{ fontSize: 18, transform: 'rotate(90deg)' }} />
      </Button>
    </Popover>
  );
}

export default ActionsPopup;
