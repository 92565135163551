import dayjs from 'dayjs';
import { IChat, Message } from '../../../hooks/api/chats';
import { User } from '../../../store/auth';
import { sortChatsByLastMssgTime } from './storage-utils';

export type ViewMode = 'desktop' | 'tablet' | 'mobile';

export const TABLET_MAX_SIZE = 1024;
export const MOBILE_MAX_SIZE = 600;

export function getViewMode(element: HTMLDivElement | HTMLElement | null = document.body): ViewMode {
  if (element) {
    const { width } = element.getBoundingClientRect();

    if (width > TABLET_MAX_SIZE) {
      return 'desktop';
    }

    if (width > MOBILE_MAX_SIZE) {
      return 'tablet';
    }

    return 'mobile';
  }

  return 'desktop';
}

export const getUserDisplayName = (user: User) => {
  let displayName = `${user?.firstName ? `${user?.firstName} ` : ''}${user?.lastName || ''}`;

  if (displayName === '') {
    if (user?.email) {
      displayName = user.email;
    } else {
      displayName = 'User';
    }
  }

  return displayName;
};

export const formatChatTime = (time: dayjs.Dayjs): string => {
  const now = dayjs();
  const isSameYear = time.year() === dayjs().year();

  if (isSameYear) {
    if (now.isSame(time, 'day')) {
      return time.format('h:mm a');
    } if (now.diff(time, 'day') === 1) {
      return 'Yesterday';
    }

    return time.format('MMM D.');
  }

  return time.format('DD.MM.YY');
};

/** --- Handling messages and chats list update --- */
export type MessageEvent = 'add' | 'delete' | 'update';

interface UpdateMessagesParams {
  event: MessageEvent;
  chatId: string;
  message: Message;
  prevMessages: Record<string, Message[]>;
}

export const getUpdateMessages = ({
  event,
  chatId,
  message,
  prevMessages,
}: UpdateMessagesParams): Record<string, Message[]> => {
  const updatedMessages = { ...prevMessages };

  switch (event) {
    case 'delete':
      updatedMessages[chatId] = (updatedMessages[chatId] || []).filter(
        (msg) => msg.id !== message.id,
      );
      break;
    case 'add':
      updatedMessages[chatId] = [
        ...(updatedMessages[chatId] || []),
        message,
      ];
      break;
    case 'update':
      updatedMessages[chatId] = (updatedMessages[chatId] || [])
        .map((oldMsg) => (oldMsg.id === message.id ? message : oldMsg));
      break;
    default:
      break;
  }

  return updatedMessages;
};

interface UpdateChatsParams {
  event: MessageEvent;
  chatId: string;
  message: Message;
  messages: Record<string, Message[]>;
  prevChats: IChat[];
}

export const getUpdateChats = ({
  event,
  chatId,
  message,
  messages,
  prevChats,
}: UpdateChatsParams): IChat[] => {
  const updatedChats = [...prevChats];

  const chatIndex = updatedChats?.findIndex((chat) => chat.id === chatId);

  if (chatIndex !== -1) {
    const chatMessages = messages[chatId] || [];
    /** default value is for 'delete' event */
    let newLatestMessage = chatMessages[chatMessages.length - 1] || null;

    if (event === 'add') {
      newLatestMessage = message;
    } else if (event === 'update' && message.id === newLatestMessage?.id) {
      newLatestMessage = message;
    }

    updatedChats[chatIndex] = {
      ...updatedChats[chatIndex],
      messages: newLatestMessage ? [newLatestMessage] : [],
    };
  }

  return sortChatsByLastMssgTime(updatedChats);
};
