import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actions, useRedirectSelector } from '../../../store/common';

export default function ReduxRedirect() {
  const navigate = useNavigate();
  const date = useRedirectSelector();
  const dispatch = useDispatch();

  useEffect(() => {
    if (date && date.redirect && date.redirect.to) {
      navigate(date.redirect.to, date.redirect?.options);

      dispatch({
        type: actions.triggerRedirect.toString(),
        payload: null,
      });
    }
  }, [date?.redirect]);

  return null;
}
