import React, { ReactElement } from 'react';
import { useAuth, User } from '../store/auth';
import SignIn from '../pages/Auth/SignIn';
import { Route, RouteElements, RouteRoles } from './index';
import NotFound from '../pages/NotFound';

export function doesUserHaveAccess(roles?: RouteRoles, user?: User): boolean {
  if (roles && roles.length > 0 && user) {
    const roleListString = roles.filter((role) => typeof role === 'string') as string[];

    if (roleListString.length > 0 && roleListString.includes(user?.role)) {
      return true;
    }

    const roleListFn = roles.filter((role) => typeof role === 'function') as ((user: User) => boolean)[];

    if (roleListFn.length > 0 && roleListFn.some((role) => role(user))) {
      return true;
    }

    return false;
  }

  return true;
}

interface PrivateRouteProps {
  children?: ReactElement | React.ReactNode

  item?: Route

  element?: React.ReactNode

  elements?: RouteElements
}

function PrivateRoute({
  children, item, element, elements,
}: PrivateRouteProps): React.ReactNode | null | undefined {
  const { authorized, user } = useAuth();

  if (user && item && item.roles) {
    let isBlocked = false;
    let roles;
    let route = item;

    while (route.roles) {
      roles = route.roles;

      if (!doesUserHaveAccess(roles, user)) {
        isBlocked = true;
        break;
      } else if (route.parent) {
        route = route.parent;
      } else {
        break;
      }
    }

    if (isBlocked) {
      return <NotFound />;
    }
  }

  if (!authorized) {
    return <SignIn />;
  }

  if (elements && user) {
    if (elements[user.role]) {
      return elements[user.role];
    }

    if (elements['*']) {
      return elements['*'];
    }

    return <NotFound />;
  }

  if (element) {
    return element;
  }

  return children;
}

export default PrivateRoute;
