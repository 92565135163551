import React from 'react';
import { Form as AntdForm } from 'antd';
import { Action } from '@triare/auth-redux';
import { Callbacks, Rule } from 'rc-field-form/lib/interface';
import { FormProps as AntdFormProps } from 'rc-field-form/lib/Form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useAuth } from '../../../../../store/auth';
import AlertSuccess, { AlertSuccessProps } from './Alert/Success';
import AlertError, { AlertErrorProps } from './Alert/Error';
import Submit from '../../../../Common/Form/Button/Submit';

import styles from '../../index.module.scss';

interface FormProps<P> extends AntdFormProps {
  disableAlertSuccess?: true;
  requiredFields?: '*' | string[];
  validationRules: Rule;
  styles?: { [key: string]: string };

  children?: React.ReactNode;
  alertSuccess?: AlertErrorProps;
  alertError?: AlertSuccessProps;

  signUp: (payload: P) => Action | void;
  onFinishFailed?: Callbacks['onFinishFailed'];
}

function Form<P>({
  disableAlertSuccess,
  validationRules,
  styles: additionalStyles = {},
  requiredFields = '*',
  children,
  alertSuccess,
  alertError,
  signUp,
  initialValues = { remember: true },
  onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  form,
  ...props
}: FormProps<P>): React.ReactNode {
  const { signUp: { loading, response } } = useAuth();
  const { t } = useTranslation();

  return (
    <AntdForm
      className={clsx(styles.form, additionalStyles?.form)}
      name="signUp"
      autoComplete="off"
      layout="vertical"
      onFinish={signUp}
      onFinishFailed={onFinishFailed}
      initialValues={initialValues}
      {...props}
    >
      {children}

      <div className={styles.alertWrapper}>
        {disableAlertSuccess ? null : (
          <AlertSuccess response={response} {...alertSuccess} />
        )}
        <AlertError {...alertError} />
      </div>

      <Submit
        requiredFields={requiredFields}
        className={clsx(styles?.submit, additionalStyles?.submit)}
        loading={loading}
        size="large"
      >
        Sign Up
      </Submit>
    </AntdForm>
  );
}

export default Form;
