import React, { useEffect, useState } from 'react';
import {
  Button, Col, Form, Input, Row, UploadFile,
} from 'antd';
import ImageUpload from '../../../Common/ImageUpload';

function FormImage() {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  /** Set value in Form.Item when file list changes. These image urls will be available in form.values */
  useEffect(() => {
    form.setFieldValue('imageUrls', fileList.map((img) => img.url));
    if (fileList.length > 0) {
      form.validateFields(['imageUrls']);
    }
  }, [fileList]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      /** Use hook to send data here */
      console.log('Form values:', values);
    });
  };

  /** Example of 'initialValues'. Use on 'Edit' pages with uploader */
  /*
  const [initialValues, setInitialValues] = useState(initialState);

  const handleInitialFileList = (data: Recipe) => {
    const recipeFiles = data.imageUrls.map((url) => (generateFileObjFromUrl(url)));

    setFileList(recipeFiles);
  };

  useEffect(() => {
    if (recipeById.data) {
      setInitialValues(filterObjectProps(recipeById.data, keysToFilterInitial));
      handleInitialFileList(recipeById.data);
    }
  }, [recipeById.data]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(initialValues);
  }, [initialValues]);
  */

  return (
    <Form
      form={form}
      name="image-upload-form"
      onFinish={handleSubmit}
      layout="vertical"
      initialValues={{ name: '', label: '', imageUrls: [] }}
    >
      <Row gutter={12}>
        <Col span={12}>
          <Form.Item name="name" label="Name">
            <Input placeholder="Enter" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="label" label="Label">
            <Input placeholder="Enter" />
          </Form.Item>
        </Col>

        <Col span={24}>
          <ImageUpload
            name="imageUrls"
            fileList={fileList}
            setFileList={setFileList}
            multiple
          />
        </Col>
      </Row>

      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form>
  );
}

export default FormImage;
