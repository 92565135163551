import React, { useEffect, useState } from 'react';
import { Form as AntdForm, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  signInByUsername as signInByUsernameAction,
  SignInByUsernameAction,
} from '@triare/auth-redux/dist/saga/auth/signIn';
import { Action } from '@triare/auth-redux';
import { Error, AnyObject } from '@triare/auth-redux/dist/saga/common';
import {
  createRulesForAntd,
  validationSignInUsernameAndPassword,
} from '../../../../../utils/validations';
import Form from '../Form';
import { RootState } from '../../../../../store';
import { moduleName } from '../../../../../store/auth';
import { ContentProps } from '../index';
import Footer from '../Footer';
import Separator from '../../../../Common/Form/Separator';
import ButtonGoogle from '../Form/ButtonGoogle';
import ButtonApple from '../Form/ButtonApple';
import ButtonFacebook from '../Form/ButtonFacebook';

const validationRules = createRulesForAntd(validationSignInUsernameAndPassword);

interface UsernameAndPasswordProps extends ContentProps {
  authorized: boolean;
  response: AnyObject | null;
  error: Error | null;
  signIn: (payload: SignInByUsernameAction) => Action
}

function UsernameAndPassword({
  response, error, signIn, onSubmit,
}: UsernameAndPasswordProps): React.ReactNode {
  const [signInData, setSignInData] = useState<SignInByUsernameAction | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (!error && response && onSubmit && signInData) {
      onSubmit(signInData);
    }
  }, [response, error, onSubmit, signInData]);

  return (
    <>
      <Form<SignInByUsernameAction>
        validationRules={validationRules}
        validateSubmit={({ username, password }) => !username || !password}
        signIn={(props) => {
          const data: SignInByUsernameAction = {
            ...props,
            // _query: {
            //   lang: 'en',
            // },
          };

          setSignInData(data);
          signIn(data);
        }}
        name="signInUser"
      >
        <AntdForm.Item
          name="username"
          label={`${t('userName')} *`}
          rules={[validationRules]}
        >
          <Input
            placeholder={t('userName') || 'Password'}
            size="large"
            readOnly
            onFocus={(e) => e.currentTarget.removeAttribute('readonly')}
          />
        </AntdForm.Item>

        <AntdForm.Item
          name="password"
          label={`${t('password')} *`}
          rules={[validationRules]}
        >
          <Input.Password placeholder="Password" size="large" />
        </AntdForm.Item>
      </Form>

      <Separator />

      <AntdForm.Item>
        <ButtonGoogle onSubmit={onSubmit} />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonApple onSubmit={onSubmit} />
      </AntdForm.Item>

      <AntdForm.Item>
        <ButtonFacebook onSubmit={onSubmit} />
      </AntdForm.Item>

      <Footer />
    </>
  );
}

export default connect((state: RootState) => ({
  authorized: state[moduleName].authorized,
  response: state[moduleName].signIn.response,
  error: state[moduleName].signIn.error,
}), {
  signIn: signInByUsernameAction,
})(UsernameAndPassword);
