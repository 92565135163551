import React from 'react';
import EmojiPicker, { EmojiClickData, EmojiStyle, SuggestionMode } from 'emoji-picker-react';
import clsx from 'clsx';
import { Tabs, TabsProps } from 'antd';
import Scroll from '../../Scroll';
import { AdditionsType, useChatsProvider } from '../../../../context/chats';
import { IconDockToLeft } from '../../Icon';

import siderStyles from './index.module.scss';
import mainChatStyles from '../index.module.scss';

interface SiderRightProps {
  onEmojiClick: (emoji: EmojiClickData) => void;
  containerClassName?: string;
  wrapperClassName?: string;
}

function SiderRight({ containerClassName, wrapperClassName, onEmojiClick }: SiderRightProps) {
  const { openAdditions, handleOpenAdditions } = useChatsProvider();

  const tabs: TabsProps['items'] = [
    {
      key: 'emoji',
      label: 'Emoji',
      children: (
        <Scroll className={siderStyles.content}>
          <EmojiPicker
            // open
            emojiStyle={EmojiStyle.NATIVE}
            suggestedEmojisMode={SuggestionMode.FREQUENT}
            skinTonesDisabled
            className={siderStyles.picker}
            onEmojiClick={(emoji, event) => {
              console.log('emoji:', emoji, '| event:', event);
              onEmojiClick(emoji);
            }}
            // lazyLoadEmojis
            autoFocusSearch={false}
            reactionsDefaultOpen={false} // TODO check how this should work.
            // reactions={[]}
            onReactionClick={(emoji, event) => {
              console.log('reaction: emoji:', emoji, '| event:', event);
            }}
            // allowExpandReactions
          />
        </Scroll>
      ),
    },
    {
      key: 'sticker',
      label: 'Stickers',
      children: 'Content Stickers tab',
    },
  ];

  return (
    <div className={containerClassName}>
      <div className={wrapperClassName}>
        {/** If emoji or stickers selected as sidebar - show tabs: */}
        {openAdditions.mode === 'emoji' || openAdditions.mode === 'sticker' ? (
          <Tabs
            items={tabs}
            defaultActiveKey="emoji"
            activeKey={openAdditions.mode}
            onChange={(activeKey) => {
              handleOpenAdditions({ open: true, mode: activeKey as AdditionsType });
            }}
            tabBarStyle={{ paddingLeft: 44, height: 64, margin: 0 }}
            tabBarExtraContent={openAdditions.open ? (
              <IconDockToLeft
                className={clsx(mainChatStyles.icon, mainChatStyles.left)}
                onClick={() => handleOpenAdditions({ open: false })}
              />
            ) : null}
            destroyInactiveTabPane={false}
          />
        ) : null}
        {openAdditions.mode === 'files' ? (
          <div>
            Files
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SiderRight;
