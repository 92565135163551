import React, { useRef, useState } from 'react';
import {
  FormInstance, Upload, Button, UploadFile, UploadProps, Alert, App, Modal, Form,
} from 'antd';
import clsx from 'clsx';
import {
  DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined,
} from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import ImgCrop, { ImgCropProps } from 'antd-img-crop';
import { getMessageInError } from '../../../hooks/fetch';
import { getBase64 } from '../../../utils';
import { useUploadFile } from '../../../hooks/file';
import { useMessageError } from '../../../hooks/common';

import styles from './index.module.scss';

export const getFullFileUrl = (url: string | undefined) => (url ? `${process.env.REACT_APP_AMAZON_S3_URL}/${url}` : '');

/** Use this function for initial values generation on 'Edit' pages  */
export const generateFileObjFromUrl = (url: string) : UploadFile => ({
  url,
  uid: url,
  name: url.replace('images/', ''),
  status: 'done',
});

interface InputCodeWrapperProps extends React.PropsWithChildren {
  name: string;
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  required?: boolean;
  form?: FormInstance;
  accept?: string;
  uploadUrl?: string;
  multiple?: boolean;
  className?: string;
  cropperProps?: Partial<ImgCropProps>
  wide?: boolean;
}

interface PreviewModal {
  image: string;
  title: string;
  visible: boolean;
}

function ImageUpload({
  form,
  name,
  fileList,
  setFileList,
  required = false,
  accept = '.jpg,.png,.jpeg',
  uploadUrl = 'common/upload-image',
  multiple = false,
  className,
  cropperProps,
  wide = false,
}: InputCodeWrapperProps) {
  const { message } = App.useApp();
  const uploadRef = useRef<HTMLButtonElement | null>(null);
  const uploader = useUploadFile(uploadUrl);
  const [preview, setPreview] = useState<PreviewModal>({ visible: false, image: '', title: '' });

  useMessageError([uploader]);

  const cropperParams: Partial<ImgCropProps> = {
    rotationSlider: true,
    showReset: true,
    resetText: 'Сбросить',
    modalTitle: 'Редактировать фото',
    aspect: wide ? 16 / 9 : undefined,
    ...cropperProps,
  };

  /** Handlers */
  const handleCancelPreview = () => setPreview({ ...preview, visible: false });

  const onPreview = async (file: UploadFile & {timeStampString?: string;}) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj as Blob) as string;
    }

    setPreview({
      ...preview,
      image: file.url ? getFullFileUrl(file.url) : (file.preview || file.thumbUrl || ''),
      title: file.timeStampString || '',
      visible: true,
    });
  };

  const onRemove = (file: UploadFile) => {
    const index = fileList.indexOf(file);
    const newFileList = [...fileList];

    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const [replacingIndex, setReplacingIndex] = useState<number | null>(null);
  const onReplace = (file: UploadFile) => {
    const index = fileList.indexOf(file);

    setReplacingIndex(index);
    uploadRef.current?.click();
  };

  const beforeUpload = async (file: RcFile, FileList?: RcFile[], index?: number) => {
    /** Validation: */
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      message.error('You can only upload JPG/PNG file!');

      return false;
    }

    if (!(file.size / 1024 / 1024 < 10)) {
      message.error('Image must smaller than 10MB!');

      return false;
    }

    /** Functionality */
    const newFile: UploadFile = {
      originFileObj: file, uid: file.uid, name: file.name, status: 'done',
    };

    newFile.preview = await getBase64(file) as string;

    /** If function receive 'index' as prop, then we don't add new img at the end of array, but set in given position */
    if (index !== null && index !== undefined) {
      const newFileList = [...fileList];

      newFileList[index] = newFile;
      setFileList(newFileList);
    } else /** If we dont have index, then do regular state update depends on 'is multiple' mode or not */
      if (multiple) {
        setFileList([...fileList, newFile]); // as UploadFile
      } else {
        setFileList([newFile]);
      }

    /** Upload image to API */
    const formData = new FormData();

    formData.append('file', newFile.originFileObj as Blob);
    uploader.fetch(formData)
      .then((res) => {
        if (res?.url) {
          setFileList((prevFileList) => {
            const updatedData = prevFileList.map((item) => (
              /** After upload finish function updates file object and adds 'url' from reponse into file */
              item.uid === file.uid ? { ...item, url: res.url } : item
            ));

            return updatedData;
          });
        }
      });

    return false;
  };

  const props: UploadProps = {
    /** 'Multiple' here should be false, to allow upload only one image at once. File list can be any length.
     * If you need to upload multiple files at once, then you need to remove <ImgCrop> wrapper on uploaders
     *
     * - It 'multiple' prop from params will work anyway, because of 'beforeUpload' structure.
     * It have 'if (multiple)', which checks is need to replace current item or to add to the end of array */
    multiple: false,
    showUploadList: false,
    name,
    accept,
    onRemove,
    beforeUpload,
    fileList,
    listType: 'picture-card',
  };

  return (
    <div>
      <div className={clsx(styles.uploader, className, {
        [styles.singleUpload]: !multiple,
        [styles.wide]: wide,
      })}
      >
        {fileList.map((file) => (
          <div key={file.uid} className={clsx(styles.imageContainer, styles.overlay)}>
            <div className={styles.contentInOverlay}>
              <EyeOutlined
                className={styles.btn}
                onClick={(e) => {
                  e.stopPropagation();
                  onPreview(file);
                }}
                title="Preview image"
              />
              <EditOutlined
                className={styles.btn}
                onClick={(e) => {
                  e.stopPropagation();
                  onReplace(file);
                }}
                title="Replace image"
              />
              <DeleteOutlined
                className={styles.btn}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove(file);
                }}
                title="Delete image"
              />
            </div>
            <img
              className={styles.uploadedImg}
              src={file.preview || getFullFileUrl(file.url) || file.thumbUrl}
              alt={file.name}
            />
          </div>
        ))}
        <Form.Item
          name={name}
          rules={required ? [{ required: true, message: 'This field is required' }] : undefined}
          className="no-space-form-item"
        >
          <ImgCrop {...cropperParams}>
            <Upload
              {...props}
              className={styles.upload}
            >
              {!multiple && fileList.length > 0 ? null : (
                <div className={styles.uploadBtn}>
                  <PlusOutlined />
                  Загрузить
                </div>
              )}
            </Upload>
          </ImgCrop>
        </Form.Item>

        <ImgCrop {...cropperParams}>
          <Upload
            name="upload-for-replace"
            className={styles.hiddenUpload}
            beforeUpload={(file) => {
              if (replacingIndex !== null && replacingIndex !== undefined) {
                beforeUpload(file, undefined, replacingIndex);
                setReplacingIndex(null);
              }

              return false;
            }}
          >
            <Button ref={uploadRef}>
              Replace
            </Button>
          </Upload>
        </ImgCrop>

      </div>

      {uploader.error ? (
        <Alert
          style={{ marginTop: 8 }}
          type="error"
          message={getMessageInError(uploader.error)}
          closable
          onClose={uploader.clearError}
        />
      ) : null}

      <Modal title={preview.title} footer={null} centered open={preview.visible} onCancel={handleCancelPreview}>
        <img alt="preview" style={{ width: '100%' }} src={preview.image} className={styles.previewModalImage} />
      </Modal>
    </div>
  );
}

export default ImageUpload;
