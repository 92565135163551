export enum ReactionEnum {
  LIKE = 'like',
  DISLIKE = 'dislike',
  SMILE = 'smile',
  CRY = 'cry',
  HEART = 'heart',
}

export enum ReactionEmojisEnum {
  'like' = '👍',
  'dislike' = '👎',
  'smile' = '😀',
  'cry' = '😢',
  'heart' = '❤️',
  // TODO expand this list:
  // all emojis: https://www.quackit.com/character_sets/emoji/emoji_v3.0/unicode_emoji_v3.0_characters_all.cfm
  // OR use lib to display emojis https://www.npmjs.com/package/node-emoji
  'laugh' = '😂',
  'wink' = '😉',
  'sunglasses' = '😎',
  'kiss' = '😘',
  'angry' = '😡',
  'surprised' = '😮',
  'clap' = '👏',
  'ok' = '👌',
  'party' = '🎉',
  'star' = '⭐',
  'fire' = '🔥',
  'poop_shit' = '💩',
  // TODO remove _shit? // Add List of keywords for each emoji? and if search for one of they, suggest this emoji
  // or use https://www.npmjs.com/package/node-emoji to search?
  'thinking' = '🤔',
  'pray' = '🙏',
  // 'thumbsup' = '👍🏻',
  // 'thumbsdown' = '👎🏻',
  'wave' = '👋',
  'hug' = '🤗',
  'sleep' = '😴',
  'muscle' = '💪',
  'monocle' = '🧐',
  'robot' = '🤖',
  'zany' = '🤪',
  'exploding' = '🤯',
  'party_face' = '🥳',
  'money' = '🤑',
  'facepalm' = '🤦',
  'shrug' = '🤷',
  'celebrate' = '🥂',
  'sweat_smile' = '😅',
  'nerd' = '🤓',
  'rofl' = '🤣',
  'face_with_rolling_eyes' = '🙄',
  'sleepy' = '😪',
  'unicorn' = '🦄',
  'strawberry' = '🍓',
  'peach' = '🍑',
  'banana' = '🍌',
}

export default {
  ReactionEnum,
};
