import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert, Form, Input, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  confirmEmail as confirmEmailAction,
  confirmClear as confirmClearAction,
  confirmErrorClear as confirmErrorClearAction,
  ConfirmResponse, ConfirmEmailAction,
} from '@triare/auth-redux/dist/saga/auth/confirm';
import { Error } from '@triare/auth-redux/dist/saga/common';
import { Action } from '@triare/auth-redux';
import Wrapper from '../Wrapper';
import { getMessageInError } from '../../../../hooks/fetch';
import { createRulesForAntd, validationNewPassword } from '../../../../utils/validations';
import Submit from '../../../Common/Form/Button/Submit';
import { RootState } from '../../../../store';
import { moduleName } from '../../../../store/auth';

const validationRules = createRulesForAntd(validationNewPassword);

export interface ConfirmEmailProps {
  mode?: string;
  loading: boolean;
  response: ConfirmResponse | null;
  error: Error | null;
  confirmEmail: (payload: ConfirmEmailAction) => Action<ConfirmEmailAction>;
  confirmClear: () => Action;
  confirmErrorClear: () => Action;
}

function ConfirmEmail({
  mode: modeParam, response, loading, error, confirmEmail, confirmErrorClear, confirmClear,
}: ConfirmEmailProps): React.ReactNode {
  const { t } = useTranslation();
  const { mode = modeParam, secretKey } = useParams();
  const navigate = useNavigate();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    if (secretKey && mode === 'none') {
      confirmEmail({
        secretKey,
      });
    }
  }, [secretKey]);

  useEffect(() => {
    if (!loading && !error) {
      if (response) {
        setRedirect(true);
      } else if (redirect) {
        navigate('/');
      }
    }
  }, [response, error, loading, redirect]);

  if (mode === 'password') {
    return (
      <Wrapper>
        <h1 style={{ textAlign: 'center' }}>
          Email confirmation!
        </h1>

        <div style={{ padding: '16px 32px', textAlign: 'center' }}>
          In order to complete your sign-up process, please enter your password.
        </div>

        <Form
          layout="vertical"
          onFinish={({ password }) => {
            confirmEmail({
              secretKey: secretKey || '',
              password,
            });
          }}
        >
          <Form.Item
            name="password"
            label={`${t('password')} *`}
            rules={[validationRules]}
          >
            <Input.Password placeholder="Password" size="large" />
          </Form.Item>

          {response && !error ? (
            <Alert
              type="success"
              message="Signed in successfully!"
              style={{ margin: '8px 0 24px' }}
              closable
              onClose={() => confirmClear()}
            />
          ) : null}

          {error ? (
            <Alert
              style={{ margin: '8px 0 24px' }}
              type="error"
              message={getMessageInError(error)}
              closable
              onClose={confirmErrorClear}
            />
          ) : null}

          <Submit
            size="large"
          >
            Confirm
          </Submit>
        </Form>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h1 style={{ textAlign: 'center' }}>
        Your email confirm
        {' '}
        {/* eslint-disable-next-line no-nested-ternary */}
        {error ? '' : (response || redirect ? 'successfully!' : <Spin />)}
      </h1>

      {error ? (
        <Alert
          style={{ marginTop: 8 }}
          type="error"
          message={getMessageInError(error)}
          closable
          onClose={confirmErrorClear}
        />
      ) : null}
    </Wrapper>
  );
}

export default connect((state: RootState) => ({
  loading: state[moduleName].confirm.loading,
  response: state[moduleName].confirm.response,
  error: state[moduleName].confirm.error,
}), {
  confirmEmail: confirmEmailAction,
  confirmClear: confirmClearAction,
  confirmErrorClear: confirmErrorClearAction,
})(ConfirmEmail);
