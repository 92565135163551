import { Modal } from 'antd';
import React from 'react';
import { ModalFuncProps } from 'antd/es/modal/interface';
import { ModalStaticFunctions } from 'antd/es/modal/confirm';

import clsx from 'clsx';
import styles from './index.module.scss';

export interface UseSimpleModalProps extends ModalFuncProps {
  type?: 'info' | 'success' | 'error' | 'warn' | 'warning' | 'confirm',
  contentH2?: string
  contentSpan?: string
}

interface UseSimpleModal {
  open: (props?: UseSimpleModalProps) => void
  modal: Omit<ModalStaticFunctions, 'warn'>
  contextHolder: React.ReactElement
}

export function useSimpleModal(options: UseSimpleModalProps = {}): UseSimpleModal {
  const { type = 'confirm', ...initProps } = options;
  const [modal, contextHolder] = Modal.useModal();

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    open: (props?: UseSimpleModalProps) => modal[type]({
      closable: true,
      title: 'Confirm',
      ...initProps,
      ...props,
      className: clsx(styles.modal, props?.className),
    }),
    modal,
    contextHolder,
  };
}

interface SimpleModalProps extends UseSimpleModalProps {
  open: boolean;
}

export default function SimpleModal(initProps: SimpleModalProps) {
  const { contextHolder } = useSimpleModal(initProps);

  return contextHolder;
}
